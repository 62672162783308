import { ErrorMessageProps } from './Model'
import './ErrorMessage.scss'
import { CheckBox } from 'devextreme-react'
import { useCallback, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'

const ErrorMessage = (props: ErrorMessageProps): JSX.Element => {
    const [showDetails, setShowDetails] = useState(false)
    const showDetailsChanged = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any): void => {
            setShowDetails(e.value)
        },
        []
    )
    return (
        <div>
            <p className={'errorMessage'}>
                {props.message !== undefined && <FontAwesomeIcon icon={faCircleExclamation} />}
                {props.message}
            </p>
            {props.detail !== '' && (
                <>
                    <CheckBox value={showDetails} onValueChanged={showDetailsChanged} text="Show details" />
                    {showDetails && (
                        <div>
                            <p className={'errorMessage'}>
                                {props.detail !== undefined && <FontAwesomeIcon icon={faCircleExclamation} />}
                                {props.detail}
                            </p>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default ErrorMessage
